<script>
export default {
  provide() {
    return { parentValidator: this.$validator };
  },
  props: {
    profile: {
      required: true,
      type: Object,
    },
  },
  data() {
    return { empProfile: this.profile };
  },

  computed: {
    validateBillingDetails() {
      return (
        // !this.errors.first('company registration number') &&
        // !this.errors.first('company VAT number') &&
        !this.errors.first('billing contact name') &&
        !this.errors.first('street address') &&
        !this.errors.first('city') &&
        !this.errors.first('state/province') &&
        !this.errors.first('postal code') &&
        !this.errors.first('email') &&
        !this.errors.first('phone number')
      );
    },
  },

  methods: {
    async updateProfile() {
      await this.$validator.validate();
      if (!this.validateBillingDetails) return;
      try {
        this.$vs.loading({ type: 'corners' });
        await this.$store.dispatch('employerModule/patch', this.empProfile);

        this.$vs.notify({
          title: 'Success!',
          text: 'Your billing details have been updated.',
        });
        this.$vs.loading.close();
      } catch (error) {
        this.$vs.loading.close();
        this.$vs.notify({
          time: 10000,
          title: 'Something went wrong',
          text: error.message,
          color: 'warning',
        });
      }
    },
  },
};
</script>

<template>
  <vx-card class="border-solid border-2 border-gray-200">
    <h2 class="pt-2">Billing Details</h2>

    <vs-divider></vs-divider>

    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
        <!-- v-validate="'required'"
          :danger="!!errors.first('company registration number')"
          :danger-text="errors.first('company registration number')" 
           data-vv-validate-on="blur"
        -->
        <vs-input
          v-model="empProfile.companyRegNo"
          name="company registration number"
          icon="work_outline"
          icon-no-border
          class="w-full truncate"
          label="Company Registration Number (optional)"
        />
      </div>
      <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
        <!-- v-validate="'required'"
          :danger="!!errors.first('company VAT number')"
          :danger-text="errors.first('company VAT number')" 
        data-vv-validate-on="blur"
        -->
        <vs-input
          v-model="empProfile.companyVatNo"
          name="company VAT number"
          icon="work_outline"
          icon-no-border
          class="w-full truncate"
          label="Company VAT Number (optional)"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
        <vs-input
          v-model="empProfile.billContact"
          v-validate="'required'"
          :danger="!!errors.first('billing contact name')"
          :danger-text="errors.first('billing contact name')"
          name="billing contact name"
          data-vv-validate-on="blur"
          icon="person"
          icon-no-border
          class="w-full"
          label="Billing Contact Name"
        />
      </div>
      <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
        <vs-input
          v-model="empProfile.billAddress"
          v-validate="'required'"
          :danger="!!errors.first('street address')"
          :danger-text="errors.first('street address')"
          name="street address"
          data-vv-validate-on="blur"
          icon="home"
          icon-no-border
          class="w-full"
          label="Street Address"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
        <vs-input
          v-model="empProfile.billCity"
          v-validate="'required'"
          :danger="!!errors.first('city')"
          :danger-text="errors.first('city')"
          name="city"
          data-vv-validate-on="blur"
          icon="apartment"
          icon-no-border
          class="w-full"
          label="City"
        />
      </div>
      <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
        <vs-input
          v-model="empProfile.billProvince"
          v-validate="'required'"
          :danger="!!errors.first('state/province')"
          :danger-text="errors.first('state/province')"
          name="state/province"
          data-vv-validate-on="blur"
          icon="location_city"
          icon-no-border
          class="w-full"
          label="State/Province"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
        <vs-input
          v-model="empProfile.billZipCode"
          v-validate="'required'"
          :danger="!!errors.first('postal code')"
          :danger-text="errors.first('postal code')"
          name="postal code"
          data-vv-validate-on="blur"
          icon="local_shipping"
          icon-no-border
          class="w-full"
          label="Postal code"
        />
      </div>
      <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
        <vs-input
          v-model="empProfile.billEmail"
          v-validate="'required|email'"
          :danger="!!errors.first('email')"
          :danger-text="errors.first('email')"
          name="email"
          data-vv-validate-on="blur"
          icon="mail_outline"
          icon-no-border
          class="w-full"
          label="Email"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-2">
        <vs-input
          v-model="empProfile.billContactNo"
          v-validate="'required|numeric'"
          :danger="!!errors.first('phone number')"
          :danger-text="errors.first('phone number')"
          name="phone number"
          data-vv-validate-on="blur"
          icon="smartphone"
          icon-no-border
          class="w-full"
          label="Phone Number"
        />
      </div>
    </div>
    <vs-button
      type="gradient"
      class="mt-2 sm:mt-6 w-full sm:w-1/3 xxl:w-1/5"
      color="#ADCA63"
      gradient-color-secondary="#ADCA63"
      @click="updateProfile()"
      >Update Billing</vs-button
    >
  </vx-card>
</template>

<style lang="scss">
* {
  font-family: inherit;
}
</style>

<script>
import ProfileTour from '../components/tours/ProfileTour.vue';
import PersonalInformation from '../components/employer-profile-components/PersonalInformation.vue';
import CompanyInformation from '../components/employer-profile-components/CompanyInformation.vue';
import BillingInformation from '../components/employer-profile-components/BillingInformation.vue';
import ProfileSide from '../components/employer-profile-components/ProfileSide.vue';

export default {
  components: {
    ProfileTour,
    BillingInformation,
    PersonalInformation,
    CompanyInformation,
    ProfileSide,
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  data() {
    return {
      profile: {},
      comp: {},
      newComp: {
        descr: '',
        selected: '',
        name: '',
        type: '',
        number: '',
        website: '',
        email: '',
        accomodation: '',
        meals: '',
        flights: '',
        leave: '',
        tips: '',
        logo: 'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/company_logos%2FRAG%20Logo%20Light%20copy.png?alt=media&token=8e48f01e-2973-4f0f-be21-287228f34f1d',
        banner: '',
        employerId: '',
      },
    };
  },

  computed: {
    companyCheck() {
      // return (
      //   this.comp.name && this.comp.name !== '' && this.comp.type && this.comp.type !== '' && this.comp.banner && this.comp.banner !== ''
      // );
      return this.$store.getters['companiesModule/getComp'];
    },
  },
  created() {
    // this.$validator.attach({ name: 'company type' });
    this.profile = Object.assign({}, this.$store.getters['employerModule/getUser']);
    if (this.$store.getters['companiesModule/getComp'] !== undefined) {
      this.comp = Object.assign({}, this.$store.getters['companiesModule/getComp']);
    } else {
      this.comp = this.newComp;
    }
  },
};
</script>

<template>
  <div class="flex flex-col-reverse lg:flex-row flex-wrap">
    <ProfileTour />
    <div class="vx-col lg:w-3/4 w-full mb-base lg:pl-2 lg:pr-5 mr-auto">
      <div v-if="!companyCheck">
        <vs-alert title="Profile Incomplete" color="rgb(231, 154, 23)" active="true">
          Please fill in your company's information and billing details. These must be completed in order to purchase Job Credits and post
          jobs.
        </vs-alert>
        <br />
      </div>
      <PersonalInformation :profile="profile" />
      <CompanyInformation :comp="comp" :profile="profile" />
      <BillingInformation :profile="profile" />
    </div>
    <div class="vx-col w-full lg:w-1/4 mb-base mr-auto">
      <ProfileSide :comp="comp" :profile="profile" />
    </div>
  </div>
</template>

<style lang="scss">
* {
  font-family: inherit;
}
</style>

<script>
import ModalDirection from '../../components/extra-components/profile/reusable-modal.vue';

export default {
  components: {
    ModalDirection,
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  props: {
    comp: {
      required: true,
      type: Object,
    },
    profile: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      company: this.comp,
      name: this.$store.getters['employerModule/getUser'].name,
      surname: this.$store.getters['employerModule/getUser'].surname,
      bannerModal: false,
      uploadBool: false,
      progressUpload: 0,
      file: File,
      uploadTask: '',
      imgUploader: false,
    };
  },

  computed: {
    validateCompUpdate() {
      return !this.errors.first('company type') && !this.errors.first('company name') && !this.errors.first('company website');
    },

    filters() {
      return this.$store.getters['filtersModule/getFilters'];
    },
    getLogo() {
      const location = 'company_logos/' + this.profile.employerId + '.jpg';
      return location;
    },
  },

  created() {
    this.$validator.attach({ name: 'company type' });
  },

  methods: {
    async updateCompany() {
      await this.$validator.validate();
      if (!this.validateCompUpdate) return;
      try {
        this.$vs.loading({ type: 'corners' });
        if (this.profile.employerId && this.profile.employerId !== '') {
          this.company.employerId = this.profile.employerId;
        } else {
          this.company.employerId = this.profile.id;
        }
        await this.$store.dispatch('companiesModule/set', this.company);

        this.$vs.notify({
          title: 'Success!',
          text: 'Your company has been updated.',
        });

        this.$vs.loading.close();
      } catch (error) {
        this.$vs.notify({
          time: 10000,
          title: 'Something went wrong',
          text: error.message,
          color: 'warning',
        });
        this.$vs.loading.close();
      }
    },

    openBannerModal() {
      this.bannerModal = !this.bannerModal;
    },

    imageHandler(value) {
      this.company.banner = value;
    },
  },
};
</script>

<template>
  <vx-card class="border-solid border-2 border-gray-200 mb-5">
    <h2 id="company-heading" class="pt-2">Company Information</h2>
    <vs-divider></vs-divider>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full">
        <vs-input
          v-model.trim="company.name"
          v-validate="'required'"
          :danger="!!errors.first('company name')"
          :danger-text="errors.first('company name')"
          name="company name"
          data-vv-validate-on="blur"
          icon="monitor"
          icon-no-border
          class="w-full lg:mb-3 mb-5"
          label="Company/Client name"
        />

        <vs-input
          v-model.trim="company.website"
          v-validate="'required|url: {require_protocol: true }'"
          :danger="!!errors.first('company website')"
          :danger-text="errors.first('company website')"
          name="company website"
          data-vv-validate-on="blur"
          icon="language"
          icon-no-border
          class="w-full lg:mb-3 mb-5"
          label="Website URL"
        />
        <div :class="'vx-col sm:w-1/1 w-full '">
          <vs-select
            v-model="company.type"
            v-validate="'required'"
            :danger="!!errors.first('company type')"
            name="company type"
            data-vv-validate-on="blur|change"
            :danger-text="'Company / Client Type is required'"
            class="w-full sm:mb-0 mb-5"
            label="Company / Client Type"
          >
            <vs-select-item v-for="(item, index) in filters['client_types']['client_types']" :key="index" :value="item" :text="item" />
          </vs-select>
        </div>
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-2 upload-zone cursor-pointer">
        <div class="drp-zne mt-5">
          <div
            class="modal-btn-banner w-full flex items-center justify-center"
            :style="'background-image: url(' + company.banner + ');background-size: cover;background-repeat: no-repeat;'"
            @click="openBannerModal"
          >
            <div v-if="!company.banner" class="flex flex-col items-center justify-center">
              <feather-icon icon="UploadIcon" class="" svg-classes="w-10 h-10" style="color: #adca63" />
              <h3>Choose a banner</h3>
            </div>
          </div>
          <div v-if="company.banner" class="edit-icon cursor-pointer">
            <label @click="openBannerModal">
              <feather-icon icon="Edit2Icon" class="rounded-full p-2" svg-classes="w-5 h-5" style="color: white; background: #353535" />
            </label>
          </div>
        </div>
      </div>
      <modal-direction v-model="bannerModal" @clicked="imageHandler"> </modal-direction>
    </div>

    <vs-button
      type="gradient"
      class="mt-2 sm:mt-6 w-full sm:w-1/3 xxl:w-1/5"
      color="#ADCA63"
      gradient-color-secondary="#ADCA63"
      @click="updateCompany()"
      >Update Company
    </vs-button>
  </vx-card>
</template>

<style lang="scss">
* {
  font-family: inherit;
}
</style>

<template>
  <div v-show="value" class="vac modal-vac modal-popup" :class="{ active: value }">
    <div class="wrapper">
      <div class="flex flex-wrap">
        <div class="vx-row">
          <div class="vx-col sm:w-1/1 w-full">
            <h2 class="pb-3 pt-0">Select a banner</h2>
            <button @click.prevent="close">X</button>
          </div>
        </div>
        <div class="vx-row">
          <div v-for="img in imgArray" :key="img" class="vx-col sm:w-1/4 banner-slct">
            <img :src="img" @click="imgHandler(img)" @click.prevent="close" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      imgArray: this.$store.state.imgArray,
    };
  },
  methods: {
    close() {
      this.$emit('input', !this.value);
    },
    imgHandler(img) {
      this.$emit('clicked', img);
    },
  },
};
</script>

<style lang="css" scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.7);
}

h2 {
  padding-bottom: 25px;
  color: #adca63;
}

.vac.modal-vac.modal-popup {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  transition: transform 0.4s;
}
.vac.modal-vac.modal-popup .wrapper {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  width: 80%;
  border: 0 !important;
  position: relative;
  border-radius: 8px;
  display: flex;
}

button {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 15px;
  background: none;
  border: none;
}

.banner-slct {
  padding: 7px;
  max-height: 170px;
}

.vac.modal-vac.modal-popup.active {
  opacity: 1;
  z-index: 99999;
}

.banner-slct img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-slct img:hover {
  cursor: pointer;
}
</style>
